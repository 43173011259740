<template>
  <div>
    <!-- faq search section -->
    <section id="faq-search-filter">
      <b-card
        no-body
        class="faq-search"
      >
        <b-card-body class="text-center">
          <h2 class="text-primary">
            {{ $t("Let's answer some questions") }}
          </h2>
          <b-card-text class="mb-2">
            {{ $t('or choose a category to quickly find the help you need') }}
          </b-card-text>

          <!-- form -->
          <b-form class="faq-search-input">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="searchbar"
                v-model="faqSearchQuery"
                :placeholder="$t('Search faq ...')"
              />
            </b-input-group>
          </b-form>
          <!-- form -->
        </b-card-body>
      </b-card>
    </section>
    <!--/ faq search section -->

    <!-- frequently asked questions tabs pills -->
    <section id="faq-tabs">
      <b-tabs
        vertical
        content-class="col-12 col-md-8 col-lg-9"
        pills
        nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
        nav-class="nav-left"
      >

        <!-- payment tab -->
        <b-tab
          v-for="(categoryObj, categoryName, index) in filteredFAQ"
          :key="categoryName"
          :active="!index"
        >

          <!-- title -->
          <template #title>
            <feather-icon
              :icon="categoryObj.icon"
              size="18"
              class="mr-1"
            />
            <span class="font-weight-bold">{{ categoryObj.title }}</span>
          </template>

          <faq-question-answer :options="categoryObj" />
        </b-tab>
        <!--/ payment tab -->

      </b-tabs>
    </section>
    <!--/ frequently asked questions tabs pills -->

    <section class="text-center my-5">
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/VmaKigyiDSQ"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
    </section>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BForm, BInputGroup, BFormInput, BCardText, BInputGroupPrepend, BTabs, BTab,
} from 'bootstrap-vue'
import FaqQuestionAnswer from './FaqQuestionAnswer.vue'

export default {
  components: {
    BForm,
    BCard,
    BCardBody,
    BInputGroup,
    BFormInput,
    BCardText,
    BInputGroupPrepend,
    BTabs,
    BTab,
    FaqQuestionAnswer,
  },
  data() {
    return {
      faqSearchQuery: '',
      faqDataEN: {
        general: {
          icon: 'HelpCircleIcon',
          title: 'General',
          subtitle: 'How can I take advantage of it?',
          qandA: [
            {
              question: 'Who it is for?',
              ans:
                `<p>Everyone can benefit from the Spider Dashboard. It complements the application and take your game analysis to the next level</p>
                <ul>
                <li>Players</li> 
                <li>Instructors and Coaches</li>
                <li>College/High School golf teams</li>
                <li>Academies</li>
                <li>PGA</li>
                <li>Federations</li>
                </ul>`,
            },
            {
              question: 'How can I become ambassador?',
              ans:
                `Entities listed above can join at any time the Spider family and become brand ambassadors promoting the brand, its image and content around the world.
                Please contact our support team support@spidergolf.fr`,
            },
            {
              question: 'How can I get certified?',
              ans:
                'Online courses and webinars are available for Teaching professionals to become <b>Spider Coach</b> certified.',
            },
          ],
        },
        'strokes gained': {
          icon: 'BarChart2Icon',
          title: 'Strokes Gained',
          subtitle: 'How can SG help my game?',
          qandA: [
            {
              question: 'What is it?',
              ans:
                `Strokes Gained is a way of analyzing where a player sits when comparing every aspect of their performance with players in the rest of the field (or baseline reference).<br />
                This can be in a multitude of ways (off-the-tee, approach, tee-to-green, putting, <b>total</b>, distances, etc.)<br />
                SG total: simply compares a player's score to the field average. For example, a player will gain two strokes on the field if he shoots 73 on a day when the field averages 75. A player who shoots 76 on that day loses one stroke to the field.<br />
                This can be applied to all areas of the game. <br />
                When the number collected is positive *ex* +2.342, the player has <b>gained</b> 2.342 shots from the baseline reference <br />
                When the number collected is negative *ex* -1.543, the player has <b>lost</b> 1.543 shots from the baseline reference. 
                `,
            },
            {
              question:
                'Benchmarks?',
              ans:
                `Your Spider Dashbaord has multiple SG benchmarks to choose from (Men + Women) classified by scores. 
                You can select the score bracket reference that matches your needs and compare analysis results to your optimal projections`,
            },
          ],
        },
        community: {
          icon: 'UsersIcon',
          title: 'My Community',
          subtitle: 'How can I connect with my players?',
          qandA: [
            {
              question: 'How do I add/delete players in my community?',
              ans:
                `Dashboard community is <b>view only</b> which means that you cannot add/delete players from the web Dashboard, you need to connect with your players via your Spider app community feature and send them student requests.
                Once connected with all your students in your app, players will automatically be uploaded in your Dashboard`,
            },
            {
              question: 'Can I create/add Groups/Coaches?',
              ans:
                'Yes you can create as many groups as necessary in your community. Groups can also be indentified as Coaches, academies, sections, etc.',
            },
          ],
        },
        Licenses: {
          icon: 'ChevronsRightIcon',
          title: 'My Licenses',
          subtitle: 'Which license do I need?',
          qandA: [
            {
              question: 'Can I purchase multiple premium licences?',
              ans:
                'Yes you purchase multiple licences and choose from the two options available, Lite and Standard Premium.',
            },
            {
              question: 'When are my licenses available?',
              ans:
                `Your premium licences are available right away and eligible one year after purchase date.
                If you did not activate available licences during that time (365 days) licences will be void.`,
            },
            {
              question: 'How do I activate my student(s)?',
              ans:
                `<p>Please follow these easy steps to activate your premium licences:</p>
                <ul>
                <li>Student must have valid Spider Golf free account</li>
                <li>Send premium link to Students Spider Golf registered email</li>
                <li>Student click on email activation link.</li>
                </ul>
                <p>When process completed, premium licence will be activated and player will automatically become a registered student in your community</p>`,
            },
            {
              question: 'My player did not receive the email. What should I do?',
              ans:
                `<ol>
                <li>Make sure that Spider Golf free account has been previously created and up to date</li>
                <li>Check spam folder</li>
                </ol>`,
            },
            {
              question: 'Premium features are not working, why?',
              ans:
                `<ul>
                <li>Please make sure to have the latest version available installed on your device.</li>
                <li>Disconnect/Connect to your account to soft reset the parameters</li>
                </ul>
                If problem persists, please contact our Team support@spidergolf.fr`,
            },
          ],
        },
        payments: {
          icon: 'CreditCardIcon',
          title: 'Payments',
          subtitle: 'How does it work?',
          qandA: [
            {
              question: 'What payment methods?',
              ans:
                `Stripe gateway is our preferred method. All major <b>credit cards</b> are supported.
                For <b>wire-transfer</b>, please contact directly our sales teams cd@spidergolf.fr`,
            },
            {
              question: 'Error during payment, was I invoiced?',
              ans:
                'No. Clear and concise informations are provided by our payment system, Stripe.',
            },
            {
              question: 'Can I get a refund?',
              ans:
                'All cleared payments are final and non refundable.',
            },
          ],
        },
      },
      faqDataFR: {
        general: {
          icon: 'HelpCircleIcon',
          title: 'Générale',
          subtitle: 'Comment en profiter?',
          qandA: [
            {
              question: 'Quel Public?',
              ans:
                `<p>Tout le monde peut bénéficier du Dashboard Spider. Il complète l'application et fait passer votre analyse de jeu au niveau supérieur</p>
                <ul>
                <li>Joueurs</li> 
                <li>Coaches</li>
                <li>Équipes de Club</li>
                <li>Académies</li>
                <li>Ligues</li>
                <li>Fédérations</li>
                </ul>`,
            },
            {
              question: 'Comment devenir ambassadeur?',
              ans:
                'La liste ci-dessus peut rejoindre à tout moment la Spider Family et devenir des ambassadeurs de la marque faisant la promotion de son contenu et de son image à travers le monde. Veuillez contacter notre équipe à support@spidergolf.fr',
            },
            {
              question: 'Comment obtenir une certification?',
              ans:
                'Certifications, Séminaires et Webinaires sont disponibles pour les enseignants qui souhaitent devenir <b>Spider Coach</b> certifié.',
            },
          ],
        },
        'strokes gained': {
          icon: 'BarChart2Icon',
          title: 'Strokes Gained',
          subtitle: "Comment l'intégrer dans l'analyse de son jeu?",
          qandA: [
            {
              question: 'Kézaco?',
              ans: `La traduction littérale de Strokes Gained pour les non anglicistes est “coups gagnés”.<br />
                Ces valeurs consistent donc à mesurer le nombre coups gagnés par un joueur sur la moyenne du reste du champ et/ou de la référence sélectionnée.<br />
                Prenons l’exemple le plus simple avec le SG total. Un joueur qui signe une carte de 69 alors que le reste du champ a joué en moyenne 72, obtient donc un SG Total de 3.0 (72-69). Peu importe le Par total du parcours.<br />
                Ce système peut être interprété et adapté à tous les compartiments de jeu.`,
            },
            {
              question:
                'Références Strokes Gained?',
              ans:
                `Votre Spider Dashboard a plusieurs Références SG au choix (Hommes + Femmes) classés par scores.<br />
                Vous pouvez sélectionner la référence qui correspond le mieux à vos besoins et comparer les résultats obtenus pour une analyse optimale`,
            },
          ],
        },
        community: {
          icon: 'UsersIcon',
          title: 'Ma Communauté',
          subtitle: 'Comment se connecter avec ses élèves?',
          qandA: [
            {
              question: 'Comment ajouter/supprimer des élèves dans sa communauté?',
              ans:
                `Vous devez vous connecter avec vos joueurs via la fonctionnalité communauté de votre application Spider Golf.<br />
                Une fois connecté avec tous vos élèves dans votre application, les joueurs seront automatiquement mis à jour dans votre Dashboard`,
            },
            {
              question: 'Puis-je créer/ajouter des groupes/coaches?',
              ans:
                'Oui, vous pouvez créer autant de groupes que nécessaire dans votre communauté. Les groupes peuvent également être filtrés par Coaches, académies, sections, etc..',
            },
          ],
        },
        Licenses: {
          icon: 'ChevronsRightIcon',
          title: 'Mes Licences',
          subtitle: 'Plusieurs choix pour plusieurs besoins',
          qandA: [
            {
              question: 'Puis-je acheter plusieurs licences premium?',
              ans:
                'Oui, vous pouvez acheter plusieurs licences en choisissant parmi les deux options disponibles, Performance et/ou Tour Premium.',
            },
            {
              question: 'Quand mes licences sont-elles disponibles?',
              ans:
                `Vos licences premium sont disponibles immédiatement et éligibles un an après la date d'achat.
                Si vous n'avez pas activé les licences disponibles pendant cette période (365 jours), les licences seront annulées.`,
            },
            {
              question: 'Comment activer mon/mes élève(s)?',
              ans:
                `<p>Veuillez suivre ces étapes simples pour activer vos licences premium:</p>
                <ul>
                <li>L'élève doit avoir un compte gratuit Spider Golf valide et à jour</li>
                <li>Envoyer le lien premium à l'adresse e-mail d'inscription du compte Spider Golf</li>
                <li>L'élève clique sur le lien d'activation dans l'email.</li>
                </ul>
                <p>Une fois ces étapes réalisées, la licence premium sera activée et le joueur deviendra automatiquement un élève inscrit dans votre communauté (App + Dashboard)</p>`,
            },
            {
              question: "Mon élève n'a pas reçu l'e-mail. Que dois-je faire?",
              ans:
                `<ol>
                <li>Assurez-vous de la création et la validité du compte gratuit Spider Golf de votre élève</li>
                <li>Vérifier le dossier spam de sa messagerie</li>
                </ol>`,
            },
            {
              question: 'Les fonctionnalités Premium ne fonctionnent pas, pourquoi?',
              ans:
                `<ul>
                <li>Veuillez vous assurer que la dernière version disponible est installée sur votre appareil.</li>
                <li>Déconnectez-vous/re-connectez-vous à votre compte pour réinitialiser les paramètres</li>
                </ul>
                Si le problème persiste, veuillez contacter notre équipe à support@spidergolf.fr`,
            },
          ],
        },
        payments: {
          icon: 'CreditCardIcon',
          title: 'Paiements',
          subtitle: 'Comment ça marche?',
          qandA: [
            {
              question: 'Quels modes de paiement?',
              ans:
                `Nous avons choisi la plateforme STRIPE pour gérer les paiements.
                Pour tous <b>virements</b>, veuillez contacter directement notre équipe commerciale à cd@spidergolf.fr`,
            },
            {
              question: 'Erreur lors du paiement, ai-je été facturé?',
              ans:
                'Non. Des informations claires et concises sont fournies par notre partenaire, STRIPE.',
            },
            {
              question: 'Puis-je obtenir un remboursement?',
              ans:
                'Tous paiements validés sont finaux et non remboursables.',
            },
          ],
        },
      },
      faqDataCN: {
        general: {
          icon: 'HelpCircleIcon',
          title: '一般',
          subtitle: '我如何利用它？',
          qandA: [
            {
              question: '这是给哪些人使用的？',
              ans:
          `<p>每个人都可以从Spider控制面板中受益。它是对应用程序的补充，并将你的游戏分析提升到一个新的层次。</p>
          <ul>
          <li>玩家</li> 
          <li>导师和教练</li>
          <li>大学/高中高尔夫球队</li>
          <li>专科学院</li>
          <li>职业高尔夫协会</li>
          <li>联盟</li>
          </ul>`,
            },
            {
              question: '我如何才能成为品牌大使?',
              ans:
          `以上列出的对象可以随时加入Spider家族并成为品牌大使，在世界各地宣传品牌、品牌形象和品牌内容。
      请联系我们的支持团队support@spidergolf.fr`,
            },
            {
              question: '我如何获得认证？',
              ans:
          `我们提供在线课程和网络研讨会，可供教学专业人员获得<b>Spider教练</ b>认证。
      (链接到网站内的页面)`,
            },
          ],
        },
        'strokes gained': {
          icon: 'BarChart2Icon',
          title: 'Strokes Gained',
          subtitle: '得分杆数如何帮助我的比赛？',
          qandA: [
            {
              question: '这是什么？',
              ans:
          `得分杆数是一种分析玩家所处位置的方法，它将玩家在各个方面的表现与其他玩家进行比较(或基线参考)。<br />
      这可以通过多种方式实现（离开发球台、近距离切球、发球台至果岭之间、推杆、<b>总杆数</b>、距离、等等）<br />
      得分杆数总得分： 将玩家的得分与场上平均得分进行比较。例如，如果一名玩家在场上平均75杆的情况下挥73杆，那么他将在场上获得两杆。而挥76杆的玩家则会丢失一杆。<br />
      这适用于游戏的所有领域。<br />
      当收集到的数字为正数 *例如* +2.342 时，玩家按基线参考<b>获得</b>2.342杆<br />
      当收集到的数字为负数 *例如* -1.543 时，玩家按基线参考<b>丢失</b>1.543杆。 
          `,
            },
            {
              question: '基准？',
              ans:
          `你的Spider控制面板有多个得分杆数基准（男+女）可供选择，并按分数分类。 
      你可以选择符合你需求的分数级别，比较分析结果以作最佳的预测`,
            },
          ],
        },
        community: {
          icon: 'UsersIcon',
          title: '我的社群',
          subtitle: '我如何与我的玩家们联系？',
          qandA: [
            {
              question: '如何在社群中添加/删除玩家？',
              ans:
          `控制面板社群<b>仅用于查看</b>，这表示你无法从网上控制面板添加/删除玩家，你需要通过你的Spider应用社群功能与玩家们联系，并向他们发送学生请求。
      一旦在应用程序中与所有学生连接，玩家们将自动上载到你的控制面板中。`,
            },
            {
              question: '我可以创建或添加群组/教练吗?',
              ans:
          '可以，你可以在社群中创建任意数量的群组。群组也可以被识别为教练、专科学院、部门等。',
            },
          ],
        },
        Licenses: {
          icon: 'ChevronsRightIcon',
          title: '我的许可证',
          subtitle: '我需要哪个许可证？',
          qandA: [
            {
              question: '我可以购买多个高级会员许可证吗？',
              ans:
          '你可以购买多个许可证，并从两个选项中进行选择： 精简高级会员和标准高级会员。',
            },
            {
              question: '我的许可证何时可用？',
              ans:
          `你的高级会员许可证可在购买后立即使用，有效期为一年。
      如果你在有效期间（365天内）没有激活许可证，许可证将失效。`,
            },
            {
              question: '如何激活我的学生?',
              ans:
          `<p>请按以下简单步骤启动你的高级会员许可证:</p>
          <ul>
          <li>学生必须拥有有效的Spider Golf免费帐户</li>
          <li>发送高级会员链接到学生的Spider Golf注册邮箱</li>
          <li>学生点击电子邮件激活链接</li>
          </ul>
          <p>当过程完成时，高级会员许可证将被激活，玩家将自动成为你的社群里的注册学生</p>`,
            },
            {
              question: '我的玩家没有收到邮件。我该怎么办?',
              ans:
          `<ol>
          <li>请确保Spider Golf免费帐户已创建并无失效</li>
          <li>请检查垃圾邮件文件夹</li>
          </ol>`,
            },
            {
              question: '高级会员功能无法启用，为什么？',
              ans:
          `<ul>
          <li>请确保在你的设备上安装了最新版本</li>
          <li>断开/连接到你的帐户以软重置参数</li>
          </ul>
          如果问题仍然存在，请联系我们的团队： support@spidergolf.fr`,
            },
          ],
        },
        payments: {
          icon: 'CreditCardIcon',
          title: '付款',
          subtitle: '它如何进行？',
          qandA: [
            {
              question: '有哪些付款方式？',
              ans:
          `Stripe是我们的首选付款方式。我们支持所有主要的<b>信用卡</b>。
      如需<b>电汇</b>，请直接联系我们的销售团队： cd@spidergolf.fr`,
            },
            {
              question: '付款过程中出现错误，我是否已被开具发票?',
              ans:
          '不，我们的支付系统Stripe提供清晰明了的信息。',
            },
            {
              question: '我可以得到退款吗?',
              ans:
          '所有结清的款项均为最终付款，不可退还。',
            },
          ],
        },
      },
      faqDataDE: {
        general: {
          icon: 'HelpCircleIcon',
          title: 'Allgemein',
          subtitle: 'Wie kann ich den Vorteil nutzen?',
          qandA: [
            {
              question: 'Für wen ist es?',
              ans:
          `<p>Jeder kann von dem Spider Dashboard profitieren. Es ergänzt die Anwendung und bringt Ihre Spielanalyse auf die nächste Stufe</p>
          <ul>
          <li>Spieler</li> 
          <li>Trainers und Coaches</li>
          <li>Golfschlägermannschaften</li>
          <li>Akademien</li>
          <li>PGA</li>
          <li>Verbände</li>
          </ul>`,
            },
            {
              question: 'Wie kann ich Botschafter werden?',
              ans:
          `Die oben aufgeführten Einrichtungen können jederzeit der Spider-Familie beitreten und Markenbotschafter werden, um die Marke, ihr Image und ihre Inhalte weltweit zu fördern.
      Bitte kontaktieren Sie unser Support-Team support@spidergolf.fr`,
            },
            {
              question: 'Wie kann ich mich zertifizieren lassen?',
              ans:
          'Für Lehrende gibt es Online-Kurse und Webinare, um <b>Spider Coach</b> zertifiziert zu werden.',
            },
          ],
        },
        'strokes gained': {
          icon: 'BarChart2Icon',
          title: 'Strokes Gained',
          subtitle: 'Wie kann SG meinem Spiel helfen?',
          qandA: [
            {
              question: 'Was ist das?',
              ans:
          `Strokes Gained ist eine Methode, um zu analysieren, wo ein Spieler steht, wenn er jeden Aspekt seiner Leistung mit dem Rest des Feldes (oder der Basislinie) vergleicht.<br />
      Dies kann auf vielfältige Weise geschehen (vom Abschlag, Annäherung, Abschlag-zu-Grün, Putten, <b>Gesamt</b>, Entfernungen, etc.)<br />
      SG total: vergleicht einfach den Score eines Spielers mit dem Durchschnitt des Feldes. Zum Beispiel gewinnt ein Spieler zwei Schläge auf das Feld, wenn er an einem Tag, an dem das Feld einen Durchschnitt von 75 hat, 73 schlägt. Ein Spieler, der an diesem Tag 76 Schläge erzielt, verliert einen Schlag auf das Feld.<br />
      Dies kann auf alle Bereiche des Spiels angewendet werden. <br />
      Wenn die gesammelte Zahl positiv ist *ex* +2,342, hat der Spieler <b>2,342 Schläge von der Grundlinie gewonnen</b>.
      Wenn die gesammelte Anzahl negativ ist *ex* -1,543, hat der Spieler <b>1,543 Schüsse von der Grundlinienreferenz verloren</b>. 
          `,
            },
            {
              question:
          'Benchmarks?',
              ans:
          `Ihr Spider Dashboard hat mehrere SG-Benchmarks zur Auswahl (Männer + Frauen), die nach Scores geordnet sind. 
      Sie können die Score-Bracket-Referenz auswählen, die Ihren Bedürfnissen entspricht, und die Analyseergebnisse mit Ihren optimalen Projektionen vergleichen`,
            },
          ],
        },
        community: {
          icon: 'UsersIcon',
          title: 'Meine Community',
          subtitle: 'Wie kann ich mich mit meinen Spielern verbinden?',
          qandA: [
            {
              question: 'Wie kann ich Spieler in meiner Community hinzufügen/löschen?',
              ans:
          'Die Dashboard-Community ist <b>nur zur Ansicht</b>, was bedeutet, dass Sie Spieler nicht über das Web-Dashboard hinzufügen/löschen können. Sie müssen sich mit Ihren Spielern über die Community-Funktion der Spider-App verbinden und ihnen Schüleranfragen senden.Sobald Sie mit allen Ihren Schülern in Ihrer App verbunden sind, werden die Spieler automatisch in Ihr Dashboard hochgeladen',
            },
            {
              question: 'Kann ich Gruppen/Coaches erstellen/hinzufügen?',
              ans:
          'Ja, Sie können so viele Gruppen wie nötig in Ihrer Community erstellen. Gruppen können auch als Coaches, Akademien, Sektionen, etc. bezeichnet werden.',
            },
          ],
        },
        Licenses: {
          icon: 'ChevronsRightIcon',
          title: 'Meine Lizenzen',
          subtitle: 'Welche Lizenz benötige ich?',
          qandA: [
            {
              question: 'Kann ich mehrere Premium-Lizenzen erwerben?',
              ans:
          'Ja, Sie erwerben mehrere Lizenzen und wählen aus den beiden verfügbaren Optionen Lite und Standard Premium.',
            },
            {
              question: 'Wann sind meine Lizenzen verfügbar?',
              ans:
          `Ihre Premium-Lizenzen sind ab sofort verfügbar und ein Jahr nach Kaufdatum aktivierbar.
      Wenn Sie verfügbare Lizenzen in diesem Zeitraum (365 Tage) nicht aktiviert haben, verfallen sie.`,
            },
            {
              question: 'Wie aktiviere ich meine(n) Schüler?',
              ans:
          `<p>Bitte folgen Sie diesen einfachen Schritten, um Ihre Premium-Lizenzen zu aktivieren:</p>
          <ul>
          <li>Schüler muss einen gültigen kostenlosen Spider Golf Account haben</li>
          <li>Premium-Link an die bei Spider Golf registrierte E-Mail des Schülers senden</li>
          <li>Schüler klickt auf E-Mail-Aktivierungslink.</li>
          </ul>
          <p>Wenn der Prozess abgeschlossen ist, wird die Premium-Lizenz aktiviert und der Spieler wird automatisch ein registrierter Student in Ihrer Community</p>`,
            },
            {
              question: 'Mein Spieler hat die E-Mail nicht erhalten. Was soll ich tun?',
              ans:
          `<ol>
          <li>Vergewissern Sie sich, dass das kostenlose Konto von Spider Golf zuvor erstellt wurde und auf dem neuesten Stand ist</li>
          <li>Überprüfen Sie den Spam-Ordner</li>
          </ol>`,
            },
            {
              question: 'Die Premium-Funktionen funktionieren nicht, warum?',
              ans:
          `<ul>
          <li>Bitte stellen Sie sicher, dass Sie die neueste Version auf Ihrem Gerät installiert haben</li>
          <li>Trennen Sie die Verbindung zu Ihrem Konto, um die Parameter zurückzusetzen</li>
          </ul>
          Wenn das Problem weiterhin besteht, kontaktieren Sie bitte unser Team support@spidergolf.fr`,
            },
          ],
        },
        payments: {
          icon: 'CreditCardIcon',
          title: 'Payments',
          subtitle: 'Wie funktioniert das?',
          qandA: [
            {
              question: 'Welche Zahlungsarten?',
              ans:
          `Das Stripe-Gateway ist unsere bevorzugte Methode. Es werden alle gängigen <b>Kreditkarten</b> unterstützt.
      Für <b>Überweisung</b> wenden Sie sich bitte direkt an unsere Vertriebsteams cd@spidergolf.fr`,
            },
            {
              question: 'Fehler bei der Zahlung, wurde mir eine Rechnung gestellt?',
              ans:
          'Nein. Klare und übersichtliche Informationen werden von unserem Zahlungssystem, Stripe, bereitgestellt.',
            },
            {
              question: 'Kann ich eine Rückerstattung bekommen?',
              ans:
          'Alle verrechneten Zahlungen sind endgültig und nicht erstattungsfähig.*',
            },
          ],
        },
      },
      faqDataES: {
        general: {
          icon: 'HelpCircleIcon',
          title: 'General',
          subtitle: '¿Cómo puedo aprovecharlo?',
          qandA: [
            {
              question: '¿para quién es?',
              ans:
          `<p>Todos pueden aprovechar el Panel de Control de Spider. Se complementa con la aplicación y lleva a otro nivel el análisis de tu juego</p>
          <ul>
          <li>Jugadores</li> 
          <li>Profesores y entrenadores</li>
          <li>Equipos de golf</li>
          <li>Academias</li>
          <li>PGA</li>
          <li>Federaciones</li>
          </ul>`,
            },
            {
              question: '¿Cómo puedo convertirme en embajador?',
              ans:
          `Las entidades arriba listadas pueden sumares e a la familia Spider en cualquier momento, y convertirse en embajadores para promocionar la marca, su imagen y contenidos en todo el mundo.
      Contáctese con nuestro equipo de soporte en support@spidergolf.fr`,
            },
            {
              question: '¿Cómo consigo la habilitación?',
              ans:
          `Hay disponibles cursos y webinarios en línea para que profesores profesionales  se conviertan en <b>Entrenador Spider</b> habilitados.
      (Enlace a la página en el sitio)`,
            },
          ],
        },
        'strokes gained': {
          icon: 'BarChart2Icon',
          title: 'Strokes Gained',
          subtitle: '¿Cómo ayudan a mi juego los SG?',
          qandA: [
            {
              question: '¿Qué es?',
              ans:
          `Los Golpes Ganados son una forma de analizar dónde está parado un jugador al comparar cada aspecto de su desempeño con jugadores en el resto del campo (o un estándar de referencia).<br />
      Esto se puede hacer de muchas maneras (salida, approach, tee a green, putting, <b>total</b>, distancias, etc.)<br />
      Total GG: Compara la puntuación de un jugador con el promedio del campo. Por ejemplo, un jugador ganará dos golpes en el campo si anota 73 golpes en un día en que el promedio del campo es 75. Un jugador que anota 76 ese día, pierde un golpe contra el campo.<br />
      Esto puede aplicarse a todas las áreas del juego. <br />
      Cuando la cantidad acumulada es positiva, p.ej. +2,342, el jugador ha <b>ganado</b> 2,342 golpes respecto del estándar de referencia <br />
      Cuando la cantidad acumulada es negativa, p.ej. -1,543, el jugador ha <b>perdido</b> 1,543 golpes respecto del estándar de referencia. 
          `,
            },
            {
              question:
          '¿Estándar?',
              ans:
          `El panel de control de Spider tiene múltiples estándares de GG entre los cuales elegir (Caballeros + Damas) clasificados por puntuación. 
      Puede elegir el rango de puntuación de referencia que mejor se adapte a sus necesidades y comparar los resultados del análisis para hacer proyecciones óptimas`,
            },
          ],
        },
        community: {
          icon: 'UsersIcon',
          title: 'Mi comunidad',
          subtitle: '¿Cómo puedo conectarme con mis jugadores?',
          qandA: [
            {
              question: '¿Cómo puedo añadir/borrar jugadores en mi comunidad?',
              ans:
          'La comunidad del panel de control es <b>sólo para ver</b>, lo que significa que no puede añadir/borrar jugadores desde el panel de control web, tiene que conectarse con sus jugadores mediante la función comunidad de la app Spider y enviarles solicitud de estudiante. Una vez conectado con todos sus estudiantes en la app, los jugadores serán subidos al panel de control automáticamente',
            },
            {
              question: 'Puedo crear/añadir Grupos/Entrenadores?',
              ans:
          'Sí, puede crear tantos grupos como sea necesario en su comunidad. Los grupos también pueden ser identificados como Entrenadores, academias, secciones, etc.',
            },
          ],
        },
        Licenses: {
          icon: 'ChevronsRightIcon',
          title: 'Mis licencias',
          subtitle: '¿Qué licencias necesito?',
          qandA: [
            {
              question: '¿Puedo comprar múltiples licencias premium?',
              ans:
          'Sí, puede comprar múltiples licencias y elegir entre las dos opciones disponibles, Lite y Standard Premium.',
            },
            {
              question: 'Cuando estarán disponibles mis licencias?',
              ans:
          `Sus licencias premium están disponibles inmediatamente y durante un año luego de la fecha de compra.
      Si no activa las licencias disponibles durante ese tiempo (365 días), las licencias se anularán.`,
            },
            {
              question: '¿Cómo activo a mi (s) estudiante (s)?',
              ans:
          `<p>Siga estos sencillos pasos para activar sus licencias premium:</p>
          <ul>
          <li>El estudiante debe tener una cuenta gratuita válida de Spider Golf</li>
          <li>Envíe el enlace premium al email registrado por los estudiantes en Spider Golf</li>
          <li>El estudiante toca el enlace de activación del email.</li>
          </ul>
          <p>Cuando se completa el proceso, la licencia premium será activada y el jugador automáticamente se convierte en un estudiante registrado en su comunidad</p>`,
            },
            {
              question: 'Mi jugador no recibió el email. ¿Qué debo hacer?',
              ans:
          `<ol>
          <li>Asegúrese de que la cuenta gratuita de Spider Golf haya sido creada previamente y esté al día</li>
          <li>Verifique la carpeta de spam</li>
          </ol>`,
            },
            {
              question: 'Las funciones premium no funcionan, ¿por qué??',
              ans:
          `<ul>
          <li>Asegúrese de tener la última versión disponible instalada en su dispositivo.</li>
          <li>Desconecte/conecte su cuenta para reiniciar los parámetros</li>
          </ul>
          Si el problema persiste, póngase en contacto con nuestro equipo de soporte en support@spidergolf.fr`,
            },
          ],
        },
        payments: {
          icon: 'CreditCardIcon',
          title: 'Pagos',
          subtitle: '¿Cómo funciona?',
          qandA: [
            {
              question: '¿Métodos de pago?',
              ans:
          `Stripe gateway es nuestro método de pago preferido. Soporta las principales <b>tarjetas de crédito</b>.
      Para <b>Transferencia electrónica</b>, contáctese directamente con nuestro equipo de ventas en cd@spidergolf.fr`,
            },
            {
              question: 'Error durante el pago, ¿me facturaron?',
              ans:
          'No. Nuestro sistema de pago, Stripe, proporciona información clara y concisa.',
            },
            {
              question: '¿Puedo recibir un reembolso?',
              ans:
          'Todos los pagos son finales, y no son reembolsables.',
            },
          ],
        },
      },
      faqDataJP: {
        general: {
          icon: 'HelpCircleIcon',
          title: '一般的な質問',
          subtitle: 'どうやって利用できますか?',
          qandA: [
            {
              question: '誰が利用できますか?',
              ans:
          `<p>Spider Dashboardの便利な機能は誰でも利用することができます。アプリをパワーアップし、あなたのゲーム分析を次のレベルに引き上げます</p>
          <ul>
          <li>プレーヤー</li> 
          <li>インストラクターやコーチ</li>
          <li>ゴルフクラブチーム</li>
          <li>アカデミー</li>
          <li>PGA</li>
          <li>連盟</li>
          </ul>`,
            },
            {
              question: 'どうすればアンバサダーになれますか?',
              ans:
          `上記の団体は、いつでもSpider familyに参加することができ、世界中でブランドやそのイメージ、コンテンツを宣伝するブランドアンバサダーになることができます。
      弊社のサポートチーム support@spidergolf.fr までご連絡ください。`,
            },
            {
              question: '認定を受けるにはどうすればいいですか?',
              ans:
          'ティーチングプロフェッショナルが<b>Spider Coach</b>の認定を受けるためのオンラインコースやウェビナーが用意されています。',
            },
          ],
        },
        'strokes gained': {
          icon: 'BarChart2Icon',
          title: 'Strokes Gained',
          subtitle: 'SGはゲームにどのように役立ちますか?',
          qandA: [
            {
              question: 'SGとは何ですか?',
              ans:
          `Strokes Gainedとは、プレーヤーのパフォーマンスのあらゆる側面をコースの他のプレーヤー達（またはベースライン）と比較したときに、プレーヤーがどの位置にいるかを分析する方法です。<br />
      この分析は多くの方法（オフザティー、アプローチ、ティートゥグリーン、パッティング、<b>トータル</b>、距離など）で行うことができます。<br />
      SGトータル：単純にプレーヤーのスコアをフィールドの平均値と比較します。例えば、フィールドの平均が75の日に73を打ったプレイヤーは、2ストロークを獲得します。また、その日に76を打ったプレイヤーは、フィールドに対して1ストロークを失うことになります。<br />
      これは、ゲームのあらゆる分野に適用することができます。<br />
      収集された数字がプラスの場合 *例* +2.342, そのプレーヤーはベースラインから2.342ショットを<b>獲得</b>したことになります。<br />
      収集された数字がマイナスの場合 *例* -1.543, そのプレーヤーはベースラインから1.543ショットを<b>失った</b>ことになります。 
          `,
            },
            {
              question:
          'ベンチマークとは何ですか?',
              ans:
          `Spider Dashboardにはスコア別に分類された(男子用 + 女子用)から選択できる複数のSGベンチマークがあります。 
      自分のニーズに合ったスコアグループを選択し、分析結果を最適な予測と比較することができます。`,
            },
          ],
        },
        community: {
          icon: 'UsersIcon',
          title: 'マイコミュニティ',
          subtitle: 'どうすれば自分のプレーヤー達とつながることができますか?',
          qandA: [
            {
              question: 'マイコミュニティでプレーヤーを追加/削除するにはどうすればよいですか?',
              ans:
          `ダッシュボードのコミュニティは<b>閲覧のみ</b>なので、 Webダッシュボードからプレーヤーを追加/削除することはできません。Spiderアプリのコミュニティ機能を使ってプレーヤーとつながり、生徒リクエストを送る必要があります。
      アプリですべての生徒と接続されると、プレイヤーは自動的にダッシュボードにアップロードされます。`,
            },
            {
              question: 'グループ/コーチを作成/追加できますか?',
              ans:
          'はい、あなたのコミュニティに必要な数のグループを作成できます。グループは、コーチ、アカデミー、セクションなどとして識別することもできます。',
            },
          ],
        },
        Licenses: {
          icon: 'ChevronsRightIcon',
          title: 'マイライセンス',
          subtitle: 'どのライセンスが必要ですか?',
          qandA: [
            {
              question: '複数のプレミアムライセンスを購入することはできますか?',
              ans:
          'はい、複数のライセンスを購入して、ライトとスタンダードプレミアムの2つのオプションから選択することができます。',
            },
            {
              question: 'いつからライセンスは利用することができますか?',
              ans:
          `プレミアムライセンスはすぐに利用でき、購入日から1年間有効です。
      その期間中（365日）に利用可能なライセンスを有効化しなかった場合、ライセンスは無効になります。`,
            },
            {
              question: '生徒を有効化するにはどうすればよいですか?',
              ans:
          `<p>次の簡単なステップに従って、プレミアムライセンスを有効にしてください:</p>
          <ul>
          <li>生徒は有効なSpider Golf無料アカウントを持っている必要があります</li>
          <li>生徒のSpider Golf登録メールにプレミアムリンクを送信します</li>
          <li>生徒はメールの有効化リンクをクリックします。</li>
          </ul>
          <p>プロセスが完了すると、プレミアムライセンスが有効になり、プレーヤーは自動的にあなたのコミュニティに登録された生徒になります</p>`,
            },
            {
              question: '私のプレーヤーがメールを受信しませんでした。どうすればいいですか?',
              ans:
          `<ol>
          <li>Spider Golfの無料アカウントが事前に作成され、最新であることを確認してください</li>
          <li>迷惑メールフォルダを確認してください</li>
          </ol>`,
            },
            {
              question: 'プレミアム機能が使えません。なぜですか?',
              ans:
          `<ul>
          <li>デバイスに最新バージョンがインストールされていることを確認してください。</li>
          <li>アカウントを解除/接続して、パラメータをソフトリセットします</li>
          </ul>
          問題が解決しない場合は、弊社チーム support@spidergolf.fr までご連絡ください`,
            },
          ],
        },
        payments: {
          icon: 'CreditCardIcon',
          title: 'お支払い',
          subtitle: 'どのように機能しますか?',
          qandA: [
            {
              question: 'どのような支払い方法がありますか?',
              ans:
          `Stripe でのお支払いをお勧めしています。すべての主要な<b>クレジットカード</b>がご利用いただけます。
      <b>電信送金</b>については、弊社の営業チーム cd@spidergolf.fr に直接お問い合わせください`,
            },
            {
              question: '支払い中にエラーが発生しました。請求されましたか?',
              ans:
          'いいえ。お支払いの詳しい情報は、弊社の決済システムStripeからお知らせいたします。',
            },
            {
              question: '返金してもらうことはできますか?',
              ans:
          'すべての決済済みのお支払いは最終的なものとなりますので、返金はいたしかねます。',
            },
          ],
        },
      },
      faqDataKO: {
        general: {
          icon: 'HelpCircleIcon',
          title: '일반',
          subtitle: '어떻게 잘 활용할 수 있을까요?',
          qandA: [
            {
              question: '누구를 위한 것인가요?',
              ans:
          `<p>모든 사람이 Spider 대시보드의 혜택을 누릴 수 있습니다. 애플리케이션을 보완하고 게임 분석을 한 단계 높입니다</p>
          <ul>
          <li>플레이어</li> 
          <li>지도자와 코치</li>
          <li>대학/고등학교 골프팀</li>
          <li>아카데미</li>
          <li>PGA</li>
          <li>연맹</li>
          </ul>`,
            },
            {
              question: '어떻게 하면 앰버서더가 될 수 있을까요?',
              ans:
          '위에 나열된 기업들은 언제든지 스파이더 패밀리에 가입하여 브랜드, 이미지 및 콘텐츠를 전 세계에 홍보하는 브랜드 홍보대사가 될 수 있습니다. 저희 지원팀 support@spidergolf.fr로 연락 부탁드립니다.',
            },
            {
              question: '인증을 받으려면 어떻게 해야 합니까?',
              ans:
          '온라인 과정 및 웨비나는 티칭 전문가가 되기 위해 이용할 수 있습니다. 확인된 <b>Spider 코치</b>.',
            },
          ],
        },
        'strokes gained': {
          icon: 'BarChart2Icon',
          title: 'Strokes Gained',
          subtitle: 'SG가 제 게임에 어떤 도움을 줄 수 있을까요?',
          qandA: [
            {
              question: '무엇인가요?',
              ans:
          `스트로크 게인(Strokes Gain)은 플레이어의 모든 성능을 나머지 필드(또는 기준 참조)의 플레이어와 비교할 때 플레이어의 상태를 분석하는 방법입니다.<br/>
     이는 다양한 방식으로 이루어질 수 있습니다(오프티, 접근, 티투그린, 퍼팅, 합계, 거리 등).<br/>
      총 SG: 필드 평균과 플레이어의 점수를 비교합니다. 예를 들어, 필드 평균이 75일 때 하루에 73타를 치면 필드에서 두 타를 얻을 수 있습니다. 이날 76타를 친 선수는 필드에 1타를 잃습니다.<br/>
      이것은 게임의 모든 영역에 적용될 수 있습니다.<br/>
     모인 숫자가 양수 *ex* +2.342일 때, 플레이어는 기준 기준 기준으로부터 2.342개의 샷을 얻습니다. <br/>
      수집된 숫자가 마이너스 *ex*-1.543일 때, 플레이어는 기준 기준 기준에서 1.543개의 슛을 잃습니다. 
          `,
            },
            {
              question:
          '벤치마크?',
              ans:
          `Spider 대시보드에는 점수로 분류된 (남자 + 여자) 중에서 선택할 수 있는 여러 개의 SG 벤치마크가 있습니다. 
      필요에 맞는 점수표 참조를 선택하고 분석 결과를 최적의 프로젝션과 비교할 수 있습니다.`,
            },
          ],
        },
        community: {
          icon: 'UsersIcon',
          title: '나의 커뮤니티',
          subtitle: '플레이어와 연결하려면 어떻게 해야 합니까?',
          qandA: [
            {
              question: '커뮤니티에서 플레이어를 추가/삭제하려면 어떻게 해야 합니까?',
              ans:
          `대시보드 커뮤니티는 <b>보기 전용</b>입니다. 즉, 웹 대시보드에서 플레이어를 추가/삭제할 수 없으며, 스파이더 앱 커뮤니티 기능을 통해 플레이어와 연결하여 학생 요청을 보내야 합니다.
     앱의 모든 학생과 연결되면 대시보드에 플레이어가 자동으로 업로드됩니다.`,
            },
            {
              question: '그룹/코치를 만들거나 추가할 수 있습니까?',
              ans:
          '예, 커뮤니티에서 필요한 만큼 그룹을 만들 수 있습니다. 그룹은 코치, 학원, 섹션 등으로도 구분될 수 있습니다.',
            },
          ],
        },
        Licenses: {
          icon: 'ChevronsRightIcon',
          title: '나의 라이센스',
          subtitle: '어떤 라이센스가 필요합니까?',
          qandA: [
            {
              question: '프리미엄 라이센스를 여러 개 구입할 수 있습니까?',
              ans:
          '예, 여러 라이센스를 구입하여 라이트 및 스탠다드, 프리미엄의 두 가지 옵션 중에서 선택할 수 있습니다.',
            },
            {
              question: '라이센스는 언제 사용가능합니까?',
              ans:
          `프리미엄 라이센스는 구입일로부터 1년이 지나면 바로 사용할 수 있습니다.
      해당 기간(365일) 동안 사용 가능한 라이센스를 활성화하지 않은 경우 라이센스는 무효화됩니다.`,
            },
            {
              question: '학생을 활성화하려면 어떻게 해야 합니까?',
              ans:
          `<p>프리미엄 라이센스를 활성화하려면 다음의 간단한 단계를 따르십시오.:</p>
          <ul>
          <li>학생은 유효한 Spider Golf 무료 계정을 가지고 있어야 합니다.</li>
          <li>학생용 스파이더 골프 등록 전자 메일로 프리미엄 링크를 보냅니다.</li>
          <li>학생이 이메일 활성화 링크를 클릭해야 합니다.</li>
          </ul>
          <p>프로세스가 완료되면 프리미엄 라이센스가 활성화되고 플레이어가 자동으로 커뮤니티에 등록된 학생이 됩니다.</p>`,
            },
            {
              question: '제 플레이어가 이메일을 받지 못했습니다. 어떻게 해야 하나요?',
              ans:
          `<ol>
          <li>Spider Golf 무료 계정이 이전에 생성되었으며 최신 상태인지 확인하십시오.</li>
          <li>스팸 폴더를 확인하세요.</li>
          </ol>`,
            },
            {
              question: '프리미엄 기능이 작동하지 않습니다. 이유가 무엇입니까?',
              ans:
          `<ul>
          <li>사용 가능한 최신 버전이 장치에 설치되어 있는지 확인하십시오.</li>
          <li>매개 변수를 소프트 리셋하려면 계정 연결을 해제/연결하십시오.</li>
          </ul>
          문제가 지속되면, 팀 support@spidergolf.fr에 문의하십시오.`,
            },
          ],
        },
        payments: {
          icon: 'CreditCardIcon',
          title: '결재',
          subtitle: '어떻게 되나요?',
          qandA: [
            {
              question: '어떤 결재 방법이 있나요?',
              ans:
          `Stripe Gateway는 저희가 선호하는 방법입니다. 거의 모든 <b> 신용카드</b>가 지원됩니다.
        <b>송금</b>에 대해서는 당사 영업팀 cd@spidergolf.fr'에 직접 문의하시기 바랍니다.`,
            },
            {
              question: '결제 중 오류가 발생했습니다. 제 송장 처리가 되었습니까?',
              ans:
          '아니요. 명확하고 간결한 정보는 당사의 결제 시스템인 Stripe에서 제공합니다.',
            },
            {
              question: '어떻게 환불을 받을 수 있을까요?',
              ans:
          '모든 결제는 최종적이며 환불이 불가능합니다.',
            },
          ],
        },
      },
      faqDataNL: {
        general: {
          icon: 'HelpCircleIcon',
          title: 'Algemeen',
          subtitle: 'Hoe kan ik er voordeel uit halen?',
          qandA: [
            {
              question: 'Voor wie is het bedoeld?',
              ans:
          `<p>Iedereen kan zijn voordeel doen met het Spider Dashboard. Het is een aanvulling op de applicatie en brengt je spelanalyse naar een hoger niveau</p>
          <ul>
          <li>Spelers</li> 
          <li>Instructeurs en coaches</li>
          <li>Club Golfteams</li>
          <li>Academies</li>
          <li>PGA</li>
          <li>Federaties</li>
          </ul>`,
            },
            {
              question: 'Hoe kan ik ambassadeur worden?',
              ans:
          `Bovenstaande entiteiten kunnen zich op elk moment aansluiten bij de Spider familie en merkambassadeur worden die het merk, het imago en de inhoud over de hele wereld promoot.
      Neem contact op met ons support team support@spidergolf.fr`,
            },
            {
              question: 'Hoe kan ik me laten certificeren?',
              ans:
          'Online cursussen en webinars zijn beschikbaar voor onderwijsprofessionals om <b>Spider Coach</b> gecertificeerd te worden.',
            },
          ],
        },
        'strokes gained': {
          icon: 'BarChart2Icon',
          title: 'Strokes Gained',
          subtitle: 'Hoe kan SG mijn spel helpen?',
          qandA: [
            {
              question: 'Wat is het?',
              ans:
          `Gewonnen Slagen is een manier om te analyseren waar een speler zich bevindt wanneer elk aspect van zijn prestatie wordt vergeleken met spelers in de rest van het veld (of baseline referentie).<br />
      Dit kan op een veelheid van manieren (off-the-tee, approach, tee-to-green, putten, <b>totaal</b>, afstanden, etc.)<br />
      SG totaal: vergelijkt eenvoudig de score van een speler met het gemiddelde van het veld. Bijvoorbeeld, een speler zal twee slagen winnen op het veld als hij 73 schiet op een dag waarop het veld gemiddeld 75 scoort. Een speler die op die dag 76 schiet, verliest één slag op het veld.<br />
      Dit kan op alle onderdelen van het spel worden toegepast. <br />
      Als het verzamelde aantal positief is *ex* +2.342, heeft de speler <b>2.342 slagen gewonnen</b> vanaf de basislijn.<br />
      Wanneer het aantal verzamelde schoten negatief is *ex* -1.543, heeft de speler <b>1.543 schoten verloren</b> ten opzichte van de basislijnreferentie. 
          `,
            },
            {
              question:
          'Benchmarks?',
              ans:
          `Uw Spider Dashboard heeft meerdere SG benchmarks om uit te kiezen (Mannen + Vrouwen) ingedeeld volgens scores. 
      U kunt de score bracket referentie kiezen die aan uw behoeften voldoet en de analyseresultaten vergelijken met uw optimale projecties`,
            },
          ],
        },
        community: {
          icon: 'UsersIcon',
          title: 'Mijn Gemeenschap',
          subtitle: 'Hoe kan ik in contact komen met mijn spelers?',
          qandA: [
            {
              question: 'Hoe kan ik spelers toevoegen/verwijderen in mijn gemeenschap?',
              ans:
          `Dashboard community is <b>alleen bekijken</b> wat betekent dat je geen spelers kunt toevoegen/verwijderen vanuit het web Dashboard, je moet verbinding maken met je spelers via je Spider app community functie en hen leerlingverzoeken sturen.
      Eenmaal verbonden met al je leerlingen in je app, zullen de spelers automatisch geupload worden in je Dashboard`,
            },
            {
              question: 'Kan ik groepen/coaches aanmaken/toevoegen?',
              ans:
          'Ja, u kunt zoveel groepen aanmaken als nodig zijn in uw gemeenschap. Groepen kunnen ook worden geïdentificeerd als coaches, academies, secties, enz.',
            },
          ],
        },
        Licenses: {
          icon: 'ChevronsRightIcon',
          title: 'Mijn licenties',
          subtitle: 'Welke licentie heb ik nodig?',
          qandA: [
            {
              question: 'Kan ik meerdere premium licenties kopen?',
              ans:
          'Ja u koopt meerdere licenties en kiest uit de twee beschikbare opties, Lite en Standard Premium.',
            },
            {
              question: 'Wanneer zijn mijn licenties beschikbaar?',
              ans:
          `Uw premium licenties zijn direct beschikbaar en komen één jaar na aankoopdatum in aanmerking.
      Als u beschikbare licenties gedurende die tijd (365 dagen) niet activeert, worden licenties ongeldig.`,
            },
            {
              question: 'Hoe activeer ik mijn student(en)?',
              ans:
          `<p>Volg deze eenvoudige stappen om uw premium licenties te activeren:</p>
          <ul>
          <li>Student moet een geldig Spider Golf gratis account hebben</li>
          <li>Stuur premium link naar Spider Golf geregistreerde e-mail van student</li>
          <li>Student klikt op e-mail activeringslink.</li>
          </ul>
          <p>Wanneer het proces voltooid is, zal de premium licentie geactiveerd worden en zal de speler automatisch een geregistreerde student worden in uw gemeenschap</p>`,
            },
            {
              question: 'Mijn speler heeft de e-mail niet ontvangen. Wat moet ik doen?',
              ans:
          `<ol>
          <li>Zorg ervoor dat Spider Golf gratis account eerder is aangemaakt en up to date is</li>
          <li>Controleer de spam-map</li>
          </ol>`,
            },
            {
              question: 'Premium functies werken niet, waarom?',
              ans:
          `<ul>
          <li>Zorg ervoor dat u de laatste beschikbare versie op uw apparaat hebt geïnstalleerd.</li>
      <li>Maak verbinding met uw account om de parameters opnieuw in te stellen</li>
      </ul>
      Als het probleem zich blijft voordoen, neem dan contact op met ons team support@spidergolf.fr`,
            },
          ],
        },
        payments: {
          icon: 'CreditCardIcon',
          title: 'Betalingen',
          subtitle: 'Hoe werkt het?',
          qandA: [
            {
              question: 'Welke betaalmethoden?',
              ans:
          `Stripe gateway is onze voorkeursmethode. Alle belangrijke <b>credit cards</b> worden ondersteund.
      Voor <b>overschrijving</b>, gelieve rechtstreeks contact op te nemen met onze verkoopteams cd@spidergolf.fr`,
            },
            {
              question: 'Fout tijdens betaling, was ik gefactureerd?',
              ans:
          'Nee. Duidelijke en beknopte informatie wordt verstrekt door ons betalingssysteem, Stripe.',
            },
            {
              question: 'Kan ik mijn geld terugkrijgen?',
              ans:
          'Alle verrekende betalingen zijn definitief en niet terugbetaalbaar.',
            },
          ],
        },
      },
    }
  },
  computed: {
    faqData() {
      if (this.$i18n.locale === 'fr') {
        return this.faqDataFR
      }
      if (this.$i18n.locale === 'cn') {
        return this.faqDataCN
      }
      if (this.$i18n.locale === 'de') {
        return this.faqDataDE
      }
      if (this.$i18n.locale === 'es') {
        return this.faqDataES
      }
      if (this.$i18n.locale === 'jp') {
        return this.faqDataJP
      }
      if (this.$i18n.locale === 'ko') {
        return this.faqDataKO
      }
      if (this.$i18n.locale === 'nl') {
        return this.faqDataNL
      }
      return this.faqDataEN
    },
    filteredFAQ() {
      return this.filterFAQ(this.faqSearchQuery)
    },
  },
  methods: {
    filterFAQ(param) {
      const queryLowered = param.toLowerCase()

      const filteredData = {}

      Object.entries(this.faqData).forEach(entry => {
        const [categoryName, categoryObj] = entry
        // eslint-disable-next-line arrow-body-style
        const filteredQAndAOfCategory = categoryObj.qandA.filter(qAndAObj => {
          return qAndAObj.question.toLowerCase().includes(queryLowered)
        })
        if (filteredQAndAOfCategory.length) {
          filteredData[categoryName] = { ...categoryObj, qandA: filteredQAndAOfCategory }
        }
      })

      return filteredData
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>
